<style lang="scss">
@import "assets/scss/components/header.scss";
</style>

<template>
	<header>
		<div class="left">
			<img src="/images/logo.png" class="h-full" alt="Logo de la marque">
		</div>
		<div class="right">
		</div>
	</header>
</template>

<script setup>
import { VsButton } from "vuesax-ts";
import { text2Price } from "../utils/global.mjs";
</script>

<script>
export default {
	name: "HeaderPrinters",
};
</script>
